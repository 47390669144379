<template>
    <div style="display: flex; flex-direction: column; min-height: 100vh; background-color: #F8F9FC;">
        <div style="flex: 1; overflow-y: auto;">
            <headComponent>
                <template #head>
                    <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
                </template>
            </headComponent>

            <div style="margin: auto; width: 83%;">
                <el-breadcrumb separator="/" style="margin: 24px 0 ">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>健康中国行</el-breadcrumb-item>
                </el-breadcrumb>
            </div>


            <div class="tab-box">
                <div class="tab-item" :style="{ borderBottom: tabIndex === 1 ? '2px solid #377bf3' : '' }"
                    @click="onTabClick(1)">
                    健康中国行
                </div>


            </div>


            <!-- 公告 -->
            <div v-if="tabIndex === 1">
                <div v-for="noticeContents in noticeContent" :key="noticeContents.id"
                    @click="detailPage(noticeContents.id)" style="cursor: pointer">
                    <div style=" width:  83%; margin: 42px auto auto auto;border-radius: 12px;background-color: #ffffff;"
                        class="card">
                        <div style="padding: 30px;">
                            <p style="font-weight: bold">{{ noticeContents.name }}</p>
                            <p v-if="noticeContents && noticeContents.content"
                                style="color: rgba(0, 0, 0, 0.6); margin-top: 10px;">
                                <span v-html="getTruncatedContent(noticeContents.summary)"></span>...
                            </p>
                            <p style="color: rgba(0, 0, 0, 0.6); margin-top: 10px">{{ noticeContents.updateTime }}</p>
                        </div>
                        <div style="padding: 2%;">
                            <img style="width: 150px; height: 100px; display: block" :src="noticeContents.cover" />
                        </div>
                    </div>
                </div>

                <div v-if="this.noticeContent.length <= 0">
                    <el-empty description="暂无数据" />
                </div>

                <div style="display: flex;justify-content: center;margin-top: 30px;">
                    <el-pagination :current-page="currentPage4" layout="total,  prev, pager, next, jumper"
                        :total="this.noticeContent.length">
                    </el-pagination>
                </div>
            </div>


        </div>
        <bottomComponent>
            <template #footer> </template>
        </bottomComponent>
    </div>
</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { noticeApi, messageApi, newsApi, articleApi } from '@/api/message/message'
export default {
    created() {
        document.title = "消息中心";

    },
    mounted() {
        this.articleApi();

        //this.newsList();

    },
    components: {
        bottomComponent,
        headComponent,
    },
    data() {
        return {
            pageNo: 1,
            circleUrl: "",
            tabIndex: 1,
            currentPage4: 0,

            noticeLists: {},
            noticeContent: [],
        };
    },

    methods: {
        //详情页
        detailPage(id) {
            console.log(id)
            this.$router.push("/healthyChinaInitiative/healthyChinaInitiativeDetail?id=" + id)
        },
        //健康中国行
        articleApi() {
            noticeApi(this.pageNo, "HEALTHY").then(resp => {
                this.noticeLists = resp.data.data;
                this.noticeContent = resp.data.data.content
                console.log(resp)
            })
        },


        onTabClick(index) {
            this.tabIndex = index;
        },
        getTruncatedContent(content) {
            const maxLength = 150; // 设置最大字符数
            if (content.length <= maxLength) {
                return content;
            }
            return content.slice(0, maxLength) + '...';
        }
    },
};
</script>

<style scoped>
.tab-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.tab-item {
    padding: 5px;
    text-align: center;
    margin-right: 40px;
    cursor: pointer;
}

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
</style>
