<template>
    <div class="backgroundDiv">
        <div style="display: grid; place-items: center; height: 100vh;">
            <div class="form-container" style="background-color: #FFFFFF;padding: 80px;">
                <span style="font-size: 24px; font-weight: bold;text-align: center;">填写基本信息</span>
                <el-form ref="form" :model="form" label-width="80px" label-position="top" style="width: 600px">
                    <el-form-item label="用户名">
                        <el-input v-model="my.name"></el-input>
                    </el-form-item>
                    <el-form-item label="医院">
                        <el-select v-model="my.orgId" placeholder="请选择" style="width: 600px">
                            <el-option v-for="(school, index) in schools" :key="index" :label="school.name"
                                :value="school.id" :selected="school.id === my.orgId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="科室">
                        <el-select v-model="my.professionTypeId" placeholder="请选择" style="width: 600px">
                            <el-option v-for="(departments, index) in departmentList" :key="index"
                                :label="departments.name" :value="departments.id"
                                :selected="departments.id === my.professionTypeId && my.professionTypeId === ''"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="button-container" style="margin-top: 20px;">
                    <el-button type="info" round @click="toIndex">取消</el-button>
                    <el-button type="primary" round @click="saveMyeditInfo">提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { schoolListApi, departmentListApi } from '@/api/schoolList/schoolList';
import { updateMyInfoApi, registerUpdateMyInfoApi } from '@/api/myCenter/myCenter';
export default {
    created() {
        document.title = "填写个人信息";
    },
    mounted() {
        this.schoolList();
        this.getDepartmentList();
    },
    data() {
        return {
            activeName: "second",
            my: {
                approveIdCard: "",
                approveTeacher: "",
                name: "",
                gender: "",
                mobile: "",
                profile: "",
                orgId: "",
                professionType: "",
                professionTypeId: "",
                idCardNo: "",
                userId: ""
            },
            form: {
                name: "",
                gender: "",
                profile: "",
                orgId: "",
                professionTypeId: "",
            },
            schools: [],
            // 科室信息集合
            departmentList: [],
        };
    },
    methods: {
        schoolList() {
            schoolListApi("").then(resp => {
                this.schools = resp.data.data;
                // 初始化时根据 my.orgId 进行默认选中
            });
        },
        getDepartmentList() {
            departmentListApi().then(response => {
                this.departmentList = response.data.data;
            });
        },
        saveMyeditInfo() {
            for (let key in this.form) {
                if (this.my.hasOwnProperty(key)) {
                    this.form[key] = this.my[key];
                }
            }
            console.log(1111111111)
            console.log(this.form)
            console.log(this.form.name)
            console.log(this.form.orgId)
            console.log(this.form.professionTypeId)
            registerUpdateMyInfoApi(this.form.name, this.form.orgId, this.form.professionTypeId).then(resp => {
                console.log(resp)
                if (resp.data.code == 0) {
                    this.$message.success("修改成功")
                    this.$router.push("/index")
                }
            })
        },


        toIndex() {
            localStorage.clear("token")
            this.$router.push("/index")
        }
    },
};
</script>

<style scoped>
.backgroundDiv {
    background: url("../../assets/login/background.png") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container span {
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
}
</style>