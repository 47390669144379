<template>
    <div style="display: flex; flex-direction: column; min-height: 100vh; background-color: #F8F9FC;">
        <div style="flex: 1; overflow-y: auto;">
            <headComponent>
                <template #head>
                    <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
                </template>
            </headComponent>
            <el-breadcrumb separator="/" style="margin: 24px auto 0; width: 83%;">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
                <el-breadcrumb-item>我的收藏</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="font-size: 32px; color: rgba(18, 25, 37, 1); margin: 40px auto 0; width: 83%;">
                我的收藏
            </div>
            <div style="width: 83%; margin: auto; display: flex; flex-direction: column; padding-bottom: 1%;">
                <div style="flex: 1; position: relative;"> <!-- 添加相对定位 -->
                    <div v-for="(courseList, index) in courses" :key="courseList.course.id"
                        @click="courseDetail(courseList.course.id)" class="jx1-course-div"
                        :style="{ 'margin-right': index % 4 < 3 ? '20px' : '0' }">
                        <img style="width: 100%; height: 160px; border-radius: 8px 8px 0 0"
                            :src="courseList.course.logo" />
                        <div style="display: grid;">
                            <span
                                style="font-size: 16px; margin-left: 16px; float: left; margin-right: 16px; margin-top: 16px; color: #121925;">{{
                                    courseList.course.name }}</span>
                            <span
                                style="font-size: 14px; margin-left: 16px; float: left; margin-top: 20px; color: #7B828D;">
                                {{ courseList.course.teacherName }}｜{{ courseList.course.orgName }}
                            </span>
                        </div>
                    </div>

                </div>

                <div v-if="this.courses.length <= 0">
                    <el-empty description="暂无数据" />
                </div>
                <div class="block"
                    style="margin: 32px auto 0 auto; width: 515px; text-align: center;margin-bottom: 0px;">
                    <el-pagination background @current-change="handleCurrentChange" :current-page="pageNo"
                        page-size="14" layout="prev, pager, next, total, jumper" :total="totalElements">
                    </el-pagination>
                </div>
            </div>
        </div>
        <bottomComponent>
            <template #footer> </template>
        </bottomComponent>
    </div>
</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { courseCollectApi } from "@/api/course/course"
export default {
    created() {
        document.title = "我的收藏";
        this.courseList()
    },
    components: {
        bottomComponent,
        headComponent,
    },
    data() {
        return {
            courses: {},
            pageNo: 1,
            totalElements: 0,
        };
    },
    methods: {
        courseList() {
            courseCollectApi(this.pageNo, "COLLECT").then(resp => {
                console.log(resp)
                this.courses = resp.data.data.content
                this.totalElements = resp.data.data.totalElements
                console.log(this.courses)
            })
        },
        courseDetail(id) {
            this.$router.push(`/courseDetail?id=${id}`)
        },
        handleCurrentChange(page) {
            this.pageNo = page;
            this.courseList()
        }
    }
}
</script>

<style>
.jx1-course-div {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 280px;
    width: 285px;
    margin-top: 24px;
    float: left !important;
    cursor: pointer
}
</style>