<template>
  <div style="background-color: #f8f9fc;width: 100%;">
    <!-- 头部 -->
    <headComponent>
      <template #head>
      </template>
    </headComponent>

    <!-- 轮播图 -->
    <div class="block" style="width: 90%;margin: auto;height: 400px;">
      <el-carousel style="width: 100%; overflow: hidden;height: 400px;">
        <el-carousel-item v-for="(item, index) in indexForm.bannerList" :key="index" style="height: 400px;">
          <img :src="item.cover" alt="" @click="toCourse(item.id)"
            style="height: 400px;;object-fit: cover;width: 100%;">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 新闻资讯 -->
    <div id="notification-div">
      <div id="notic1" style="border-radius: 16px; position: relative;border: #95C2FF solid 1px;">
        <div style="display: inline-block; margin-top: 18px;margin-left: 5%;">
          <img style="width: 30px;height: 30px;vertical-align: middle;" src="../../assets/index/Group.png" />
          <span
            style="vertical-align: middle;margin-top: 18px;font-size: 20px;color: #377bfe;margin-left: 4px;">通知公告</span>
        </div>

        <el-link @click="toMsg(1)"
          style="vertical-align: middle;margin-top: 23.5px;font-size: 12px;color: #377bfe;position: absolute;right: 35px;"
          :underline="false">
          查看更多
        </el-link>
        <div style="width: 90%;height: 84px; margin: 10px 0 0 5%;">

          <div style="font-size: 14px;">
            <div v-for="(noticeContents, index) in noticeContent" :key="noticeContents.id"
              @click="detailPage(noticeContents.id)" v-if="index < 3">
              <div style="display: flex; justify-content: space-between; align-items: center;padding: 6px 0;"
                class="noticeContent">
                <!-- <p style="margin: 0;width: 86%;">{{ noticeContents.name }}</p> -->

                <p style="margin: 0;">{{ truncateText(noticeContents.name) }}</p>

                <span style="font-size: 14px;color: #A7B1C0;">{{ noticeContents.updateTime.slice(0, 10) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="notic2" style="border-radius: 16px; position: relative;border: #95C2FF solid 1px;">
        <div style="display: inline-block; margin-top: 18px;margin-left: 5%;">
          <img style="width: 30px;height: 30px;vertical-align: middle;" src="../../assets/index/Frame.png" />
          <span
            style="vertical-align: middle;margin-top: 18px;font-size: 20px;color: #377bfe;margin-left: 4px;">新闻资讯</span>
        </div>

        <el-link @click="toMsg(2)"
          style="vertical-align: middle;margin-top: 23.5px;font-size: 12px;color: #377bfe;position: absolute;right: 35px;"
          :underline="false">
          查看更多
        </el-link>
        <div style="width: 90%;height: 84px; margin: 10px 0 0 5%">
          <div style="font-size: 14px;">
            <div v-for="(newsContents, index) in newsContent" :key="newsContents.id"
              @click="detailPage(newsContents.id)" v-if="index < 3">
              <div style="display: flex; justify-content: space-between; align-items: center;padding: 6px 0;"
                class="noticeContent">
                <p style="margin: 0;">{{ truncateText(newsContents.name) }}</p>
                <span style="font-size: 14px;color: #A7B1C0;">{{ newsContents.updateTime.slice(0, 10) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 课程 -->
    <div id="course-div">
      <!-- 精选课程 -->
      <div id="jx-course-div" style="width: 100%;">
        <div style="height: 34px; position: relative">
          <hr
            style="height: 30px;width: 3px;background-color: #377bfe;position: absolute;top: 0;left: 0;margin-top: 0;" />
          <span style="font-size: 24px; position: absolute; top: 0; margin-left: 16px">精选课程</span>
          <div
            style="margin-left: auto; width: 66px; height: 20px; display: flex; align-items: center; justify-content: center;">
            <el-link
              style="display: flex; align-items: center; height: 20px; padding: 0 5px; box-sizing: border-box; font-size: 14px; color: #7B828D;"
              :underline="false" href="/#/course">
              <span style="vertical-align: middle;">更多</span><img src="../../assets/myCenter/arrow.png"
                style="width: 20px; height: 20px; margin-left: 0; vertical-align: middle; " />
            </el-link>
          </div>
        </div>

        <div style="display: flex; ">
          <div v-for="(course, index) in this.indexForm.courseList.slice(0, 4)" :key="index"
            :class="'jx' + (index + 1) + '-course-div'" style="cursor: pointer;background-color: #FFFFFF;"
            @click="courseDetail(course.id)">
            <img :style="{ width: '100%', height: '160px', 'border-radius': '8px 8px 0 0' }" :src="course.logo" />
            <div style="text-align: center;height: 120px;position:relative;padding: 1%;">
              <span
                style="margin-left: 16px;float: left;font-family: PingFang SC;font-size: 18px;font-weight: 400;line-height: 29.8px;text-align: left;margin-top: 10px">
                {{ truncateText(course.name) }}
              </span>
              <div style="position:absolute;bottom:20px;left:16px;font-size:14px;font-weight:400;">
                <!-- <span style="color: #7B828D;">{{ course.teacherName }}</span> -->
                <span style="color: #A7B1C0;">{{ course.orgName }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- 系列课程 -->
      <!-- <div class="xk-course-div">
        <div style="height: 34px; position: relative">
          <hr style="
              height: 30px;
              width: 3px;
              background-color: #377bfe;
              position: absolute;
              top: 0;
              left: 0;
              margin-top: 0;
            " />
          <span style="font-size: 24px; position: absolute; top: 0; left: 6px">系列课程</span>
        </div>

        <div class="jx1-course-div">
          <img style="width: 380px; height: 160px" src="../../assets/index/Rectangle 5.png" />
          <span style="
              font-size: 16px;
              margin-left: 16px;
              float: left;
              margin-right: 16px;
            ">骨科创伤急诊一体化诊治仿真学习操作系统</span>
          <span style="
              font-size: 14px;
              margin-left: 16px;
              float: left;  
              margin-top: 20px;
            ">查振刚｜暨南大学</span>
        </div>
        <div class="jx2-course-div">
          <img style="width: 380px; height: 160px; border-radius: 8px 8px 0 0"
            src="../../assets/index/Rectangle 5 (1).png" />
          <span style="
              font-size: 16px;
              margin-left: 16px;
              float: left;
              margin-right: 16px;
            ">骨科创伤急诊一体化诊治仿真学习操作系统</span>
          <span style="
              font-size: 14px;
              margin-left: 16px;
              float: left;
              margin-top: 20px;
            ">查振刚｜暨南大学</span>
        </div>
        <div class="jx2-course-div">
          <img style="width: 380px; height: 160px; border-radius: 8px 8px 0 0"
            src="../../assets/index/Rectangle 5 (2).png" />
          <span style="
              font-size: 16px;
              margin-left: 16px;
              float: left;
              margin-right: 16px;
            ">骨科创伤急诊一体化诊治仿真学习操作系统</span>
          <span style="
              font-size: 14px;
              margin-left: 16px;
              float: left;
              margin-top: 20px;
            ">查振刚｜暨南大学</span>
        </div>
        <div class="jx2-course-div">
          <img style="width: 380px; height: 160px; border-radius: 8px 8px 0 0"
            src="../../assets/index/Rectangle 5 (3).png" />
          <span style="
              font-size: 16px;
              margin-left: 16px;
              float: left;
              margin-right: 16px;
            ">骨科创伤急诊一体化诊治仿真学习操作系统</span>
          <span style="
              font-size: 14px;
              margin-left: 16px;
              float: left;
              margin-top: 20px;
            ">查振刚｜暨南大学</span>
        </div>
      </div> -->

      <!-- 热门课程 -->
      <div style="width: 100%;height: 338px;margin-top: 48px;">
        <div style="height: 34px; position: relative">
          <hr style="
              height: 30px;
              width: 3px;
              background-color: #377bfe;
              position: absolute;
              top: 0;
              left: 0;
              margin-top: 0;
            " />
          <span style="font-size: 24px; position: absolute; top: 0; margin-left: 16px">热门课程</span>
          <div
            style="margin-left: auto; width: 66px; height: 20px; display: flex; align-items: center; justify-content: center;">
            <el-link
              style="display: flex; align-items: center; height: 20px; padding: 0 5px; box-sizing: border-box; font-size: 14px; color: #7B828D;"
              :underline="false" href="/#/course">
              <span style="vertical-align: middle;">更多</span><img src="../../assets/myCenter/arrow.png"
                style="width: 20px; height: 20px; margin-left: 0; vertical-align: middle;" />
            </el-link>
          </div>
        </div>

        <div style="display: flex; ">
          <div v-for="(course, index) in this.indexForm.hotCourseList" :key="index"
            style="cursor: pointer;background-color: #FFFFFF;" :class="'jx' + (index + 1) + '-course-div'"
            @click="courseDetail(course.id)">
            <img :style="{ width: '100%', height: '160px', 'border-radius': '8px 8px 0 0' }" :src="course.logo" />
            <div style="text-align: center;height: 120px;position:relative;padding: 1%;">
              <span style=" margin-left: 16px;float: left;font-family: PingFang SC;font-size: 18px;font-weight:
              400;line-height: 29.8px;text-align: left;margin-top: 10px">
                {{ truncateText(course.name) }}
              </span>
              <div style="position:absolute;bottom:20px;left:16px;font-size:14px;font-weight:400;">
                <!-- <span style="color: #7B828D;">{{ course.teacherName }}</span> -->
                <span style="color: #A7B1C0;">{{ course.orgName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- 新课推荐 -->
      <div class="xk-course-div">
        <div style="height: 34px; position: relative">
          <hr style="
              height: 30px;
              width: 3px;
              background-color: #377bfe;
              position: absolute;
              top: 0;
              left: 0;
              margin-top: 0;
            " />
          <span style="font-size: 24px; position: absolute; top: 0; margin-left: 16px">新课推荐</span>
          <div
            style="margin-left: auto; width: 66px; height: 20px; display: flex; align-items: center; justify-content: center;">
            <el-link
              style="display: flex; align-items: center; height: 20px; padding: 0 5px; box-sizing: border-box; font-size: 14px; color: #7B828D;"
              :underline="false" href="/#/course">
              <span style="vertical-align: middle;">更多</span><img src="../../assets/myCenter/arrow.png"
                style="width: 20px; height: 20px; margin-left: 0; vertical-align: middle;" />
            </el-link>
          </div>
        </div>

        <div style="display: flex; ">
          <div v-for="(course, index) in this.newCourseLists.slice(0, 4)" :key="index"
            :class="'jx' + (index + 1) + '-course-div'" style="cursor: pointer;background-color: #FFFFFF"
            @click="courseDetail(course.id)">
            <img :style="{ width: '100% ', height: '160px', 'border-radius': '8px 8px 0 0' }" :src="course.logo" />
            <div style="text-align: center;height: 120px;position:relative;padding: 1%;">
              <span style="margin-left: 16px;float: left;font-family: PingFang SC;font-size: 18px;font-weight:
              400;line-height: 29.8px;text-align: left;margin-top: 10px">
                {{ truncateText(course.name) }}
              </span>
              <div style="position:absolute;bottom:20px;left:16px;font-size:14px;font-weight:400;">
                <!-- <span style="color: #7B828D;">{{ course.teacherName }}</span> -->
                <span style="color: #A7B1C0;">{{ course.orgName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- <div style="height: 23px; width: 100%; text-align: center; margin-top: 16px; line-height: 23px;">
                <span v-if="school.description.length > 7"
                  style="font-size: 12px; color: #7b828d;background-color: #ebeef2;padding:5px 10px 5px 10px;">{{
                    school.description.slice(0, 7) + '...' }}</span>
                <span v-else
                  style="font-size: 12px; color: #7b828d;background-color: #ebeef2;padding:5px 10px 5px 10px;">{{
                    school.description }}</span>
              </div> -->
      <!-- 院校 -->


      <div style="width: 100%;  margin-top: 48px;">
        <div style="height: 34px; position: relative; padding-top: 24px; display: flex; align-items: center;">
          <img style="width: 30px; height: 30px" src="../../assets/index/bank-line.png" />
          <span style="font-size: 24px;margin-left: 5px;">试点建设单位</span>
        </div>

        <div style="position: relative;margin-top: 24px;">
          <div ref="scrollContainer" style="display: flex; flex-wrap: nowrap; overflow-x: hidden;">
            <div v-for="(school, index) in schools" :key="index" class="yx-course1-div">
              <img
                style="width: 30px;height:30px;object-fit: cover;box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);margin-top: 10px;"
                :src="school.license" alt="School Logo" />
              <span style="font-size: 15px; margin-top: 8px;">{{ school.name }}</span>
            </div>
          </div>


          <el-button class="big-icon-button"
            style="position: absolute; top: 50%; left: 0; transform: translateY(-50%); background-color: rgba(255, 255, 255, 0.5); border: none; outline: none;"
            @click="scrollLeft">
            <div class="change-icon">
              <i class="el-icon-arrow-left"></i>
            </div>
          </el-button>



          <el-button
            style="position: absolute; top: 50%; right: 0; transform: translateY(-50%); background-color: rgba(255, 255, 255, 0.5); border: none; outline: none;"
            @click="scrollRight">
            <div class="change-icon">
              <i class="el-icon-arrow-right"></i>
            </div>
          </el-button>
        </div>
      </div>

      <!-- 专家 -->
      <!-- <div class="xk-course-div">
        <div style="
            height: 34px;
            position: relative;
            display: flex;
            align-items: center;
          ">
          <img style="width: 30px; height: 30px" src="../../assets/index/nurse-line.png" />
          <span style="font-size: 24px">模拟医学的专家</span>
        </div>


        <div style="height: 277px; width: 100%; margin-top: 24px ;">
          <div style="
              width: 23%;
              height: 100%;
              float: left;
              border-radius: 8px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            ">
            <img style="border-radius: 8px 8px 0 0" src="../../assets/index/Rectangle 5 (4).png" />

            <div>
              <div style="display: flex; align-items: baseline">
                <span class="span1"> 周林 </span>
                <span style="
                    width: 58px;
                    padding: 1px 3px 1px 3px;
                    font-size: 12px;
                    margin: 0 0 0 12px;
                    background-color: rgba(97, 223, 200, 0.2);
                    color: #18bea0;
                    text-align: center;
                  ">
                  主任医师
                </span>
              </div>
              <div style="  margin-top: 10%;">
                <span class="span2">中国疾控中心、疾病预防中心</span>
              </div>
            </div>
          </div>


          <div class="xk-course2-div">
            <img style="border-radius: 8px 8px 0 0" src="../../assets/index/Rectangle 5 (4).png" />

            <div>
              <div style="display: flex; align-items: baseline">
                <span class="span1"> 周林 </span>
                <span style="
                    width: 58px;
                    padding: 3px 5px 3px 5px;
                    font-size: 12px;
                    margin-left: 12px;
                    background-color: rgba(97, 223, 200, 0.2);
                    color: #18bea0;
                    text-align: center;
                  ">
                  主任医师
                </span>
              </div>
              <div style="  margin-top: 10%;">
                <span class="span2">中国疾控中心、疾病预防中心</span>
              </div>
            </div>
          </div>


          <div class="xk-course2-div">
            <img style="border-radius: 8px 8px 0 0" src="../../assets/index/Rectangle 5 (4).png" />

            <div>
              <div style="display: flex; align-items: baseline">
                <span class="span1"> 周林 </span>
                <span style="
                    width: 58px;
                    padding: 3px 5px 3px 5px;
                    font-size: 12px;
                    margin-left: 12px;
                    background-color: rgba(97, 223, 200, 0.2);
                    color: #18bea0;
                    text-align: center;
                  ">
                  主任医师
                </span>
              </div>
              <div style="  margin-top: 10%;">

                <span class="span2">中国疾控中心、疾病预防中心</span>

              </div>
            </div>
          </div>
          <div class="xk-course2-div">
            <img style="border-radius: 8px 8px 0 0" src="../../assets/index/Rectangle 5 (4).png" />

            <div>
              <div style="display: flex; align-items: baseline">
                <span class="span1"> 周林 </span>
                <span style="
                    width: 58px;
                    padding: 1% 2% 1% 2%;
                    font-size: 12px;
                    margin-left: 12px;
                    background-color: rgba(97, 223, 200, 0.2);
                    color: #18bea0;
                    text-align: center;             
                  ">
                  主任医师
                </span>
              </div>
              <div style="  margin-top: 10%;">

                <span class="span2">中国疾控中心、疾病预防中心</span>

              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <bottomComponent>
      <template #footer>
      </template>
    </bottomComponent>
  </div>
</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { indexPageApi } from '@/api/index/indexApi'
import { newCourseListApi } from '@/api/course/course'
import { schoolListApi } from '@/api/schoolList/schoolList'
import { noticeApi, newsApi } from '@/api/message/message'
export default {
  components: {
    bottomComponent,
    headComponent,
  },
  created() {
    document.title = "智慧模拟医学网";
    this.index();
    //新课推荐
    this.newCourseList();
    //院校
    this.schoolList();
    //公告
    this.noticeList();
    //新闻
    this.newsList();
  },
  data() {
    return {
      indexForm: {},
      input: "",
      schools: [],
      newCourseLists: [],
      pageNo: 1,
      carouselHeight: '50vh',
      noticeLists: {},
      noticeContent: [],

      newsLists: {},
      newsContent: [],

      messageLists: {},
      messageContent: [],

      isDataLoaded: false,

    };
  },
  methods: {

    //新科推荐
    newCourseList() {
      newCourseListApi(this.pageNo).then(resp => {
        this.newCourseLists = resp.data.data.content
      })
    },

    scrollLeft() {
      const container = this.$refs.scrollContainer;
      container.scrollLeft -= container.offsetWidth / 2;
    },
    scrollRight() {
      const container = this.$refs.scrollContainer;
      container.scrollLeft += container.offsetWidth / 2;
    },

    //多出30个字省略
    truncateText(text) {
      if (text.length > 30) {
        return text.slice(0, 30) + '...';
      }
      return text;
    },

    //新闻公告详情页
    detailPage(id) {
      // console.log(id)
      this.$router.push("/messageCenter/detailPage?id=" + id)
    },
    //公告
    noticeList() {
      noticeApi(this.pageNo).then(resp => {
        this.noticeLists = resp.data.data;
        this.noticeContent = resp.data.data.content
        // console.log(resp)
      })
    },
    //新闻
    newsList() {
      newsApi(this.pageNo).then(resp => {
        this.newsLists = resp.data.data;
        this.newsContent = resp.data.data.content
        // console.log(resp)
      })
    },



    //院校
    schoolList() {
      schoolListApi("").then(resp => {
        this.schools = resp.data.data;
        console.log(this.schools)
      });
    },

    //去课程页面
    toCourse(id) {
      this.$router.push("/messageCenter/detailPage?id=" + id)
    },

    //消息页面
    toMsg(index) {
      this.$router.push("/messageCenter/msgCenter?index=" + index)
    },


    index() {
      indexPageApi().then(resp => {
        this.indexForm = resp.data.data
        // console.log(11111111111111111)
        // console.log(this.indexForm)
        // console.log(111111111111111111)
      })
    },
    handleResize() {
      // 根据窗口大小计算轮播图的高度
      this.carouselHeight = Math.min(window.innerHeight * 0.5, 500); // 例如设置为窗口高度的一半，但不超过 500px
    },

    courseDetail(id) {
      this.$router.push(`/courseDetail?id=${id}`)
    },

  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    // 在组件销毁前移除窗口大小变化的监听
    window.removeEventListener('resize', this.handleResize);
  },



};
</script>

<style scoped>
.course-item {
  width: 380px;
  margin: 10px;
}

#notification-div {
  height: 164px;
  width: 83%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

#notic1 {
  background-color: #eff5ff;
  float: left;
  width: 48%;
  height: 164px;
}

#notic2 {
  background-color: #eff5ff;
  float: right;
  width: 48%;
  height: 164px;
}

#course-div {
  width: 83%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
}

#jx-course-div {
  width: 83%;
  height: 338px;
}

.xk-course-div {
  width: 100%;
  height: 338px;
  margin-top: 48px;
}

.xk-course2-div {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
  width: 23%;
  height: 100%;
  float: left;
  margin-left: 20px;
}


.jx1-course-div {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 280px;
  width: 25% !important;
  margin-top: 24px !important;
  float: left;
}

.jx2-course-div {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 280px;
  width: 25% !important;
  margin-top: 24px !important;
  margin-left: 1.5%;
  float: left;
}

.jx3-course-div {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 280px;
  width: 25% !important;
  margin-left: 1.5%;
  margin-top: 24px;
  float: left;
}

.jx4-course-div {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 280px;
  width: 25% !important;
  margin-left: 1.5%;
  margin-top: 24px;
  float: left;
}


.jx-course-div {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 280px;
  width: 25% !important;
  margin-top: 24px;
  float: left;
}

/* 使用 nth-child 选择器来设置除第一个以外的课程的左边距 */
.jx-course-div:nth-child(n + 2) {
  margin-left: 20px;
}



.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.yx-course1-div {
  width: 10%;
  border-radius: 16px;
  /* height: 100%; */
  background: linear-gradient(to bottom, #eafffb, white);
  margin-left: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  flex: 0 0 auto;
  /* Remove scrollbar */
  overflow: hidden;
}


.yx-course1-div span {
  font-size: 15px;
  margin-top: 8px;
  display: inline-block;
  text-align: center;
}


button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-text {
  color: #a7b1c0;
  font-size: 12px;
  margin: 0 8px 0 8px;
}

.span1 {
  height: 27px;
  width: 38px;
  font-size: 19px;
  color: #121925;
  margin-left: 18px;
}

.span2 {
  color: #7b828d;
  font-size: 14px;

  padding: 18px
}

.noticeContent:hover {
  color: #5a21c4;
  cursor: pointer;
}

div[style="background-color: #f8f9fc;width: 100%;"] {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* 使用弹性布局来确保元素自适应 */
.container {
  display: flex;
  flex-wrap: wrap;
}

/* 将图片的宽度设置为相对值 */
img {
  width: 100%;
}


.el-carousel__container {
  position: relative;
  height: 400px !important;
}

.change-icon {
  font-size: 20px;
}
</style>
