import { render, staticRenderFns } from "./bottomComponent.vue?vue&type=template&id=b934b58a&scoped=true&"
import script from "./bottomComponent.vue?vue&type=script&lang=js&"
export * from "./bottomComponent.vue?vue&type=script&lang=js&"
import style0 from "./bottomComponent.vue?vue&type=style&index=0&id=b934b58a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b934b58a",
  null
  
)

export default component.exports