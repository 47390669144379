<template>

    <div style="background-color: #F8F9FC;">

        <headComponent>
            <template #head>
                <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
            </template>
        </headComponent>

        <div style="margin: auto;width: 83%">

            <el-breadcrumb separator="/" style="margin:24px 0 0 ">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>我的</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="width: 83%; height: 80px;margin:  52px auto auto auto;">
            <div style="display: flex; align-items: center;width: 100%;">
                <div style="height: 60px;">
                    <img v-if="my.profile != null" :src="my.profile"
                        style="width: 80px; height: 80px; object-fit: cover; border-radius: 50%;" />
                    <img v-if="my.profile == null" src="../../assets/myCenter/headPortrait.png"
                        style="width: 80px; height: 80px; object-fit: cover; border-radius: 50%;" />
                </div>

                <div style="float: left; margin: 0 0 0 25px; height: 60px; background-color: #f9f9f9;   ">
                    <div style="height: 25px;  padding: 8px 0;">
                        <span style="font-size: 18px; font-family: Arial, sans-serif; color: #333; margin-right: 15px;">
                            {{ my.name }}
                        </span>
                        <span
                            style="font-size: 13px; font-family: Arial, sans-serif; padding: 5px 10px; background-color: rgba(97, 223, 200, 1); border-radius: 4px; color: #fff; margin-right: 12px;">
                            {{ my.approveIdCard === 'EMPTY' && '未认证' || my.approveIdCard === 'WAIT' && '审核中' ||
                                my.approveIdCard === 'PASS' && '已认证' || my.approveIdCard === 'REFUSE' && '审核未通过' || '' }}
                        </span>
                        <span
                            style="font-size: 13px; font-family: Arial, sans-serif; padding: 5px 10px; background-color: rgba(251, 169, 10, 1); border-radius: 4px; color: #fff; margin-right: 10px;">
                            {{ my.approveTeacher === 'EMPTY' && '未认证' || my.approveTeacher === 'WAIT' && '审核中' ||
                                my.approveTeacher === 'PASS' && '已认证' || my.approveTeacher === 'REFUSE' && '审核未通过' || '' }}
                        </span>
                    </div>
                    <div style=" width: fit-content;">
                        <span
                            style="font-size: 12px; font-family: Arial, sans-serif; padding: 4px 8px; background-color: rgba(55, 123, 254, 0.1); border-radius: 4px; color: #333; margin-right: 12px;">
                            {{ my.gender == 1 ? "男" : "女" }}
                        </span>
                        <span
                            style="font-size: 12px; font-family: Arial, sans-serif; padding: 4px 8px; background-color: rgba(55, 123, 254, 0.1); border-radius: 4px; color: #333; margin-right: 12px;">
                            {{ my.orgName }}
                        </span>
                    </div>
                </div>
                <div style="width: 98px; height: 34px; background-color: rgba(255, 255, 255, 1); margin-left: auto;border-radius: 17px;display: flex; align-items: center;cursor: pointer"
                    @click="toMyEditinfo">
                    <img src="../../assets/index/editInformation.png" style="margin-left: 14px;">
                    <span style="font-size: 13px;margin-left: 3px;">编辑资料</span>
                </div>
            </div>
        </div>
        <div style="width: 83%;margin: 42px auto auto auto;border-radius: 12px;background-color: #FFFFFF;">

            <div style="width: 100%;height: 76px;">
                <div style="display: flex; align-items: center; cursor: pointer;" @click="courseCollection">
                    <img class="img" src="../../assets/myCenter/Frame 101.png" />
                    <span style="font-size: 16px;margin-left: 16px;">课程收藏</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png"
                            style="width: 24px; height: 24px;margin-right: 16px;" />
                    </span>
                </div>
            </div>


            <!-- <div class="imgDiv">
                <div style="display: flex; align-items: center; cursor: pointer;" @click="cacheCourse">
                    <img class="img" src="../../assets/myCenter/Group.png" />
                    <span style="font-size: 16px;margin-left: 16px;">缓存课程</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png"
                            style="width: 24px; height: 24px;margin-right: 16px;" />
                    </span>
                </div>
            </div> -->
            <div>
                <div style="display: flex; align-items: center; cursor: pointer;" @click="myStudy">
                    <img class="img" src="../../assets/myCenter/Group (1).png" />
                    <span style="font-size: 16px;margin-left: 16px;">学习记录</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png"
                            style="width: 24px; height: 24px;margin-right: 16px;" />
                    </span>
                </div>
            </div>
            <div>
                <div style="display: flex; align-items: center; cursor: pointer;" @click="practiceExaminationRecord">
                    <img class="img" src="../../assets/myCenter/Group (2).png" />
                    <span style="font-size: 16px;margin-left: 16px;">模拟考试记录</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png"
                            style="width: 24px; height: 24px;margin-right: 16px;" />
                    </span>
                </div>
            </div>
            <!-- <div class="imgDiv">
                <div style="display: flex; align-items: center; cursor: pointer;" @click="informationOfRegistration">
                    <img class="img" src="../../assets/myCenter/Group (3).png" />
                    <span style="font-size: 16px;margin-left: 16px;">报名信息</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png"
                            style="width: 24px; height: 24px;margin-right: 16px;" />
                    </span>
                </div>
            </div> -->

        </div>


        <div style="width: 83%;margin: 42px auto auto auto;border-radius: 12px;background-color: #FFFFFF;">
            <!-- <div style="width: 100%;height: 76px;">
                <div style="display: flex; align-items: center; cursor: pointer;" @click="appointmentLaboratory">
                    <img class="img" src="../../assets/myCenter/microscope-line.png" />
                    <span style="font-size: 16px;margin-left: 16px;">预约实验室</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png"
                            style="width: 24px; height: 24px;margin-right: 16px;" />
                    </span>
                </div>
            </div> -->
            <div class="imgDiv2">
                <div style="display: flex; align-items: center; cursor: pointer;" @click="setting">
                    <img class="img" src="../../assets/myCenter/Group (4).png" />
                    <span style="font-size: 16px;margin-left: 16px;">设置</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png"
                            style="width: 24px; height: 24px;margin-right: 16px;" />
                    </span>
                </div>
            </div>

        </div>


        <bottomComponent style="margin-top: 6%;">
            <template #footer>
            </template>
        </bottomComponent>
    </div>

</template>

<script>
import bottomComponent from '../bottomComponent.vue';
import headComponent from '../headComponent.vue'
import { myCenterApi } from '@/api/myCenter/myCenter'
export default {
    mounted() {
        this.mycenter()
    },

    created() {
        document.title = "我的";
    },
    components: {
        bottomComponent,
        headComponent
    },
    data() {
        return {
            circleUrl: "",
            my: {
                approveIdCard: "",
                approveTeacher: "",
                name: "",
                gender: "",
                mobile: "",
                profile: "",
                orgId: "",
                professionType: "",
                professionTypeId: "",
                idCardNo: "",
                userId: ""
            }
        };
    },


    methods: {
        toMyEditinfo() {
            this.$router.push("/myCenter/editInfo")
        },
        mycenter() {
            var token = localStorage.getItem("token")
            if (token == null || token == "") {
                alert("请先登录")
                this.$router.push("/login")
            }
            myCenterApi().then(resp => {
                this.my = resp.data.data
                console.log(this.my.profile)
            })
        },
        //课程收藏
        courseCollection() {
            this.$router.push("/courseCollect")
        },
        //缓存课程
        cacheCourse() {
            this.$router.push("/courseCache")
        },
        //学习记录
        myStudy() {
            this.$router.push("/study")
        },
        //模拟考试记录
        practiceExaminationRecord() {
            this.$router.push("/exam")
        },
        //报名信息
        informationOfRegistration() {

        },
        //预约实验室
        appointmentLaboratory() {
            this.$router.push("/subscribe")
        },
        //设置
        setting() {
            this.$router.push("/setting")
        },

    },


}
</script>

<style>
.img {
    width: 27px;
    height: 27px;
    margin: 25px 0 25px 25px;
}

.imgDiv {
    width: 100%;
    height: 76px;
    border-top: 1px ridge rgba(235, 238, 242, 1)
}

.imgDiv2 {
    width: 100%;
    height: 76px;
    /* border-top: 1px ridge rgba(235, 238, 242, 1) */
}
</style>