// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

// 我的页面
export const myCenterApi = () => {
  return service({
    url: "front/api/user/my",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
  });
};

// 修改个人信息
export const updateMyInfoApi = (
  name,
  gender,
  profile,
  orgId,
  professionTypeId
) => {
  return service({
    url: "front/api/user/update",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      name,
      gender,
      profile,
      orgId,
      professionTypeId,
    },
  });
};

// 注册后修改个人信息
export const registerUpdateMyInfoApi = (name, orgId, professionTypeId) => {
  return service({
    url: "front/api/user/update",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      name,
      orgId,
      professionTypeId,
    },
  });
};
