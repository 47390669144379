// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

// 公告
export const noticeApi = (pageNo, articleType) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType,
      platFlagPc: "true",
    },
  });
};

// 消息
export const messageApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "MESSAGE",
      platFlagPc: "true",
    },
  });
};

// 新闻
export const newsApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "NEWS",
      platFlagPc: "true",
    },
  });
};

// 详情页
export const detailPageApi = (id) => {
  return service({
    url: "/front/api/article/detail",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      id,
    },
  });
};

// 新闻
export const articleApi = (pageNo, articleType) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType,
      platFlagPc: "true",
    },
  });
};
