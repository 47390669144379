<template>

    <div style="display: flex; ; min-height: 98vh; background-color: #F8F9FC;">

        <div style="flex: 1; overflow-y: auto;">

            <el-breadcrumb separator="/" style="margin: 5% auto 0;width: 83%;">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myCenter/myCenter' }">我的</el-breadcrumb-item>
                <el-breadcrumb-item>设置</el-breadcrumb-item>
            </el-breadcrumb>

            <div style="width: 83%;margin: 4% auto auto auto;border-radius: 12px;background-color: #FFFFFF;">

                <div class="imgDiv" @click="changePassword">
                    <span class="imgDivTextSpan">修改密码</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png" class="imgDivImgSpan" />
                    </span>
                </div>

                <div class="imgDiv" @click="AboutUs">
                    <span class="imgDivTextSpan">关于我们</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png" class="imgDivImgSpan" />
                    </span>
                </div>

                <div class="imgDiv" @click="copyrightInformation">
                    <span class="imgDivTextSpan">版本信息</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png" class="imgDivImgSpan" />
                    </span>
                </div>

                <div class="imgDiv" @click="problemFeedback">
                    <span class="imgDivTextSpan">问题反馈</span>
                    <span style="margin-left: auto;">
                        <img src="../../assets/myCenter/arrow.png" class="imgDivImgSpan" />
                    </span>
                </div>

            </div>

        </div>
    </div>
</template>


<script>

export default {
    mounted() {
    },

    created() {
        document.title = "设置";
    },
    data() {
        return {

        };
    },

    methods: {
        //修改密码
        changePassword() {
            this.$router.push("/changePassword")
        },

        //关于我们
        AboutUs() {
            // this.$router.push("/AboutUs")
            window.open("https://test.medsimmeta.com/html/about.html")
        },

        //版权信息
        copyrightInformation() {
            this.$router.push("/copyrightInformation")
        },

        //问题反馈
        problemFeedback() {
            this.$router.push("/problemFeedback")
        },
    }


}
</script>

<style>
.imgDiv {
    width: 100%;
    border-top: 1px ridge rgba(235, 238, 242, 1);
    display: flex;
    align-items: center;
    cursor: pointer;
}

body {
    display: block;
    margin: 0px;
}

.imgDivTextSpan {
    font-size: 16px;
    margin-left: 16px;
}

.imgDivImgSpan {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}
</style>
