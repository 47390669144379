<template>
    <div class="backgroundDiv">
        <div style="text-align: center; margin-top: 4%">
            <img src="../../assets/logo/logo1.png" style="width: 48px;height: 48px;" />
            <br>
            <span style="font-size: 20px;">智慧模拟医学网</span>
        </div>

        <div style="width: 83%;margin: 3% auto auto auto;border-radius: 12px">
            <div
                style="width: 452px;height: 476px;margin: auto;margin-top: 46.73px;box-shadow: 0px 2px 6px 0px #00000026;padding: 10px 50px 20px;background-color: white;">
                <el-tabs style="display: grid; justify-content: center" :stretch="true">
                    <el-form ref="form" :model="form">
                        <span style="font-size: 14px; line-height: 40px">手机号</span>

                        <div style="width: 400px;">
                            <el-input placeholder="请输入手机号" v-model="form.mobile" style="width: 250px"></el-input>
                            <el-button type="primary" plain @click="sendCode" :disabled="counting"
                                style="margin-left: 16px; margin-right: 0; width: 134px">
                                {{ counting ? `${countdown} 秒` : '发送验证码' }}
                            </el-button>
                        </div>

                        <el-form-item label="验证码" style="margin-top: 29px">
                            <el-input placeholder="请输入验证码" v-model="form.code" style="width: 400px"></el-input>
                        </el-form-item>

                        <el-form-item label="新密码" style="margin-top: 29px">
                            <el-input placeholder="请输入新密码" v-model="form.password" style="width: 400px"></el-input>
                        </el-form-item>
                        <el-form-item style="margin-top: 50px">
                            <el-button type="primary" @click="changePwd"
                                style="width: 400px;margin: auto;">提交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tabs>
            </div>
        </div>
    </div>
</template>


<script>
import { rsa_encode, rsa_decode } from '@/utils/rsa';
import CryptoJS from 'crypto-js';
import { sendCodeApi } from '@/api/register/registerApi'
import { changePwdApi } from '@/api/setting/setting'
export default {
    mounted() {
    },

    created() {
        document.title = "忘记密码";
    },
    data() {
        return {
            counting: false,
            form: {
                mobile: "",
                code: "",
                password: "",
            },
            countdown: 60,
        };
    },

    methods: {
        //发送验证码
        sendCode() {
            if (this.form.mobile == null || this.form.mobile == "") {
                this.$message.error("请输入手机号")
            } else {
                if (!this.validatePhone(this.form.mobile)) {
                    // 手机号码格式不正确，给出提示
                    this.$message.error('手机号码格式不正确，请重新输入');
                } else {
                    this.counting = true;
                    const timer = setInterval(() => {
                        if (this.countdown > 0) {
                            this.countdown--;
                        } else {
                            clearInterval(timer);
                            this.counting = false;
                            this.countdown = 60;
                        }
                    }, 1000);
                    sendCodeApi(this.form.mobile).then(resp => {
                        if (resp.data.code == 0) {
                            this.$message.success("发送成功")
                        } else {
                            this.$message.error("发送成功失败")
                        }
                    })
                }
            }
        },


        //校验手机号码
        validatePhone(phoneNumber) {
            const phoneRegex = /^1[3-9]\d{9}$/;
            return phoneRegex.test(phoneNumber);
        },

        //修改密码
        changePwd() {
            let secretKey = "bYBAnBYL2V8r7n6spfuK6BziY5PUdqTHzxVg3RSskoA4UsNtJldF8zOolBRnpmR8";
            // 毫秒值时间戳
            const time = Date.now();
            // 使用 3des 加密密码
            let encryptedPassword = CryptoJS.enc.Utf8.parse(secretKey, this.form.password);
            //需要加密的内容
            let content = this.form.password
            let text = time + content;
            //签名
            let sign = CryptoJS.enc.Utf8.parse(secretKey, text);
            let timeBase64 = btoa(time);
            let content2 = sign + ";" + timeBase64;
            let auth = btoa(content2);
            let encodePassword = rsa_encode(this.form.password)
            changePwdApi(this.form.mobile, this.form.code, encodePassword, auth).then(resp => {
                console.log(resp);
            });
        }
    }


}
</script>

<style>
.imgDiv {
    width: 100%;
    border-top: 1px ridge rgba(235, 238, 242, 1);
    display: flex;
    align-items: center;
    cursor: pointer;
}

body {
    display: block;
    margin: 0px;
}

.backgroundDiv {
    background: url("../../assets/login/background.png") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.imgDivTextSpan {
    font-size: 16px;
    margin-left: 16px;
}

.imgDivImgSpan {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}
</style>
