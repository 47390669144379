<template>
  <div class="backgroundDiv">
    <div style="text-align: center; margin-top: 4%">
      <img src="../../assets/logo/logo1.png" style="width: 48px;height: 48px;" />
      <br>
      <span style="font-size: 20px;">智慧模拟医学网</span>
    </div>

    <div
      style="width: 452px;height: 476px;margin: auto;margin-top: 46.73px;box-shadow: 0px 2px 6px 0px #00000026;padding: 10px 50px 20px;background-color: white;">
      <el-tabs v-model="activeName" style="display: grid; justify-content: center" :stretch="true">
        <el-tab-pane label="密码登录" name="first">
          <el-form ref="form" :model="form">
            <el-form-item label="手机号">
              <el-input v-model="form.mobile" style="width: 400px"></el-input>
            </el-form-item>
            <el-form-item label=" 密码" style="margin-top: 29px">
              <el-input v-model="form.password" type="password" style="width: 400px"></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 50px">
              <el-button type="primary" @click="passwordLogin" style="width: 400px;margin: auto;">登录</el-button>
            </el-form-item>
            <div style="display: flex;justify-content: space-between;width: 100%;">
              <div style="display: flex; align-items: center;">
                <el-checkbox v-model="agreePrivacy">同意</el-checkbox>
                <el-link type="primary" @click="toPrivacyAgreement" :underline="false"
                  style="font-size: 13px">隐私协议</el-link>
              </div>
              <el-link type="primary" :underline="false" @click="forgetThePassword()"
                style="font-size: 13px">忘记密码</el-link>
            </div>
          </el-form>

          <div style="text-align: center">
            <el-link type="primary" :underline="false" style="font-size: 13px; margin-top: 49px"
              href="/#/register">还没账号？立即注册</el-link>
          </div>
        </el-tab-pane>

        <el-tab-pane label=" 验证码登录" name="second">
          <el-form ref="form2" :model="form">
            <span style="font-size: 14px; line-height: 40px">手机号</span>

            <div style="width: 400px;">
              <el-input v-model="form2.mobile" style="width: 250px"></el-input>
              <el-button type="primary" plain @click="sendCode" :disabled="counting"
                style="margin-left: 16px; margin-right: 0; width: 134px">
                {{ counting ? `${countdown} 秒` : '发送验证码' }}
              </el-button>
            </div>

            <el-form-item label="验证码" style="margin-top: 29px">
              <el-input v-model="form2.code" style="width: 400px"></el-input>
            </el-form-item>

            <el-form-item style="margin-top: 50px">
              <el-button type="primary" @click="codeLogin" style="width: 400px;margin: auto;">登录</el-button>
            </el-form-item>
            <div style="display: flex;justify-content: space-between;width: 100%;">
              <div style="display: flex; align-items: center;">
                <el-checkbox v-model="agreePrivacy">同意</el-checkbox>
                <el-link type="primary" @click="toPrivacyAgreement" :underline="false"
                  style="font-size: 13px">隐私协议</el-link>
              </div>
              <el-link type="primary" :underline="false" style="font-size: 13px"
                @click="forgetThePassword()">忘记密码</el-link>
            </div>
          </el-form>
          <div style="text-align: center">
            <el-link type="primary" :underline="false" style="font-size: 13px; margin-top: 49px"
              href="/#/register">还没账号？立即注册</el-link>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div style="margin: 2%;color: #a7b1c0;font-size: 12px;text-align: center;">
      <span>© 2023-2025 智慧模拟医学网 版权所有。<br />
        粤ICP备2022129530号-1</span>
    </div>
  </div>
</template>


<script>
import { loginApi, passwordLoginApi, existApi } from '@/api/login/loginApi.js'
import { sendCodeApi } from '@/api/register/registerApi'
export default {
  created() {
    document.title = "登录";
  },

  data() {
    return {
      counting: false,
      countdown: 60,
      activeName: "second",
      form: {
        mobile: "",
        password: ""
      },
      form2: {
        mobile: "",
        code: ""
      },
      agreePrivacy: false // 表示是否同意隐私协议
    };
  },
  methods: {
    //隐私协议
    toPrivacyAgreement() {
      this.$router.push("/PrivacyAgreement")
    },

    //忘记密码
    forgetThePassword() {
      this.$router.push("/forgetThePassword")
    },

    //账号密码登录
    passwordLogin() {
      if (!this.agreePrivacy) {
        this.$message.error("请同意隐私协议");
        return;
      }
      if (this.form.mobile == null || this.form.mobile == "") {
        this.$message.error("请输入手机号")
      } else {
        if (this.form.password == null || this.form.password == "") {
          this.$message.error("请输入密码")
        } else {
          if (!this.validatePhone(this.form.mobile)) {
            // 手机号码格式不正确，给出提示
            this.$message.error('手机号码格式不正确，请重新输入');
          } else {
            existApi(this.form.mobile).then(resp => {
              if (resp.data.code == 0) {
                passwordLoginApi(this.form.mobile, this.form.password).then(resp => {
                  if (resp.data.code == 0) {
                    this.$message.success("登录成功")
                    localStorage.setItem("token", resp.data.data.token)
                    this.$router.push("/index")
                  } else {
                    this.$message.error("密码错误")
                  }
                })
                //账号不存在
              } else if (resp.data.code == 7000) {
                this.$message.error(resp.data.message)
              }
            })
          }
        }
      }
    },

    sendCode() {
      if (!this.agreePrivacy) {
        this.$message.error("请同意隐私协议");
        return;
      }
      if (this.form2.code == null || this.form2.mobile == "") {
        this.$message.error("请输入手机号")
      } else {
        if (!this.validatePhone(this.form2.mobile)) {
          // 手机号码格式不正确，给出提示
          this.$message.error('手机号码格式不正确，请重新输入');
        } else {
          this.counting = true;
          const timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(timer);
              this.counting = false;
              this.countdown = 60;
            }
          }, 1000);
          sendCodeApi(this.form2.mobile).then(resp => {
            if (resp.data.code == 0) {
              this.$message.success("发送成功")
            } else {
              this.$message.error("发送成功失败")
            }
          })
        }
      }
    },

    //校验手机号码
    validatePhone(phoneNumber) {
      const phoneRegex = /^1[3-9]\d{9}$/;
      return phoneRegex.test(phoneNumber);
    },

    codeLogin() {
      if (!this.agreePrivacy) {
        this.$message.error("请同意隐私协议");
        return;
      }
      if (this.form2.code == null || this.form2.code == "") {
        this.$message.error("请输入验证码")
      } else {
        loginApi(this.form2.mobile, this.form2.code).then(resp => {
          console.log(111111111111)
          console.log(resp)
          if (resp.data.code == 0) {
            localStorage.setItem("token", resp.data.data.token)
            localStorage.setItem("userId", resp.data.data.info.userId)
            //判断信息是否填写
            if (resp.data.data.info.orgName == null | resp.data.data.info.orgName == "") {
              this.$router.push("/fillInformation")
            } else {
              console.log(resp.data)
              this.$message.success("登录成功")
              this.$router.push("/index")
            }

          } else {
            this.$message.error("验证码错误或过期")
          }
        })
      }
    },

  },
};
</script>

<style scoped>
.backgroundDiv {
  background: url("../../assets/login/background.png") no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>