<template>
    <div style="background-color: #f8f9fc">
        <headComponent>
            <template #head>
                <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
            </template>
        </headComponent>

        <div style="width: 83%; margin: auto;">
            <el-breadcrumb separator="/" style="margin: 24px 0 ">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/supportAndService' }">支持与服务</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="
        width: 83%;
        margin: 42px auto auto auto;
        border-radius: 12px;
        background-color: #ffffff;
      ">
            <p style="text-align: center; font-size: 24px; font-weight: bold;padding: 30px;">
                {{ this.detail.name }}
            </p>
            <p style="text-align: center; color: rgba(0, 0, 0, 0.5)">{{ this.detail.updateTime }}</p>


            <div style="margin: auto;text-align: center;background-color: aliceblue;width: 70%;border-radius: 5px;padding: 10px;"
                v-if="this.detail.summary">
                <span style="font-size: 15px;">{{ this.detail.summary }}</span>
            </div>

            <div style="margin: auto;padding: 0 3%;">
                <span style="padding: 30px;" v-html="this.detail.content"></span>
            </div>

        </div>

        <bottomComponent>
            <template #footer> </template>
        </bottomComponent>
    </div>
</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { detailPageApi } from '@/api/message/message'
export default {
    created() {
        document.title = "详情";
    },
    components: {
        bottomComponent,
        headComponent,
    },
    data() {
        return {
            circleUrl: "",
            detail: {}
        };
    },

    mounted() {
        this.detailPage()
    },

    methods: {
        detailPage() {
            console.log("id" + this.$route.query.id)
            detailPageApi(this.$route.query.id).then(resp => {
                this.detail = resp.data.data
            })
        },
    }

};
</script>

<style scoped>
.img {
    width: 27px;
    height: 27px;
    margin: 25px 0 25px 25px;
}

.imgDiv {
    width: 1306px;
    height: 76px;
    border-top: 1px ridge rgba(235, 238, 242, 1);
}
</style>
