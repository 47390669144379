import { render, staticRenderFns } from "./laboratory.vue?vue&type=template&id=92d19bec&scoped=true&"
import script from "./laboratory.vue?vue&type=script&lang=js&"
export * from "./laboratory.vue?vue&type=script&lang=js&"
import style0 from "./laboratory.vue?vue&type=style&index=0&id=92d19bec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92d19bec",
  null
  
)

export default component.exports